import {parse} from 'query-string';
import {maskUUID} from '../../utils';

// removes query params and uuids from url.
// https://app.youhodler.com/api/v1/transactions/0b1b1b1b-0b1b-0b1b-0b1b-0b1b1b1b1b1b?limit=10 =>
// https://app.youhodler.com/api/v1/transactions/:uuid
export function sanitizeUrl(url: string) {
  try {
    return maskUUID(
      url
        // remove query params and hash
        .split(/[?#]/)[0]
        // replace uuids with :uuid

        // removes https://app.youhodler.com/api from start or url (but will leave present if it's not at the start)
        .replace(/^https:\/\/(\w)+.youhodler.com\/api/, '/api')
    );
  } catch (e) {
    return url;
  }
}
// returs parsed query params from url
export function getQueryParams(url: string) {
  try {
    return parse(url.split(/[?#]/)[1]);
  } catch (e) {
    return {};
  }
}
