import {type NativeMMKV} from 'react-native-mmkv';

// copied from react-native-mmkv/src/createMMKV.mock.ts
export class InMemoryMMKVLikeStorage implements NativeMMKV {
  private _storage: Map<string, string | boolean | number | Uint8Array>;

  constructor() {
    this._storage = new Map();
  }

  clearAll = () => this._storage.clear();
  delete = key => this._storage.delete(key);
  set = (key, value) => this._storage.set(key, value);
  getString = key => {
    const result = this._storage.get(key);
    return typeof result === 'string' ? result : undefined;
  };
  getNumber = key => {
    const result = this._storage.get(key);
    return typeof result === 'number' ? result : undefined;
  };
  getBoolean = key => {
    const result = this._storage.get(key);
    return typeof result === 'boolean' ? result : undefined;
  };
  getBuffer = key => {
    const result = this._storage.get(key);
    return result instanceof Uint8Array ? result : undefined;
  };
  getAllKeys = () => Array.from(this._storage.keys());
  contains = key => this._storage.has(key);
  recrypt = () => {
    console.warn('Encryption is not supported in mocked MMKV instances!');
  };
}
