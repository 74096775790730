import {observable, action, computed} from 'mobx';
import {AppearanceServiceModule} from './Appearance';
import {SECURE_STORAGE, SIMPLE_STORAGE} from '@youtoken/ui.service-storage';

export enum GlobalCampaign {
  NEW_MINER_FLOW = 'newonboardingflow',
}

export class GlobalService {
  public appearance: AppearanceServiceModule = new AppearanceServiceModule();

  @observable
  token: string | null;

  @observable
  initialUrl: string | null = null;

  @observable
  campaign: GlobalCampaign | null = null;

  @action
  setToken(newToken?: string) {
    if (newToken) {
      SECURE_STORAGE.set('token', newToken);
      this.token = newToken;
    } else {
      this.removeToken();
    }
  }

  @action
  setInitialUrl = (path: string | null) => {
    this.initialUrl = path;
  };

  @action
  setCampaign = (campaign: GlobalCampaign | null) => {
    if (campaign) {
      SIMPLE_STORAGE.set('campaign', campaign);
      this.campaign = campaign;
    } else {
      SIMPLE_STORAGE.delete('campaign');
      this.campaign = null;
    }
  };

  @action
  removeToken() {
    SECURE_STORAGE.delete('token');
    this.token = null;
  }

  constructor() {
    this.token = SECURE_STORAGE.get('token') || null;
    this.campaign = (SIMPLE_STORAGE.get('campaign') as GlobalCampaign) || null;
  }

  @computed
  get isAuthorized() {
    return Boolean(this.token);
  }
}
