import {AppEnv} from './AppEnv';
import {getWebAppUrl} from './webAppUrl';

type ApplicationType = 'web';

/** @deprecated
 *
 * use concatenation from `ENVIRONMENT.*_URL` variables instead
 *
 * @example
 * ```ts
 * ENVIRONMENT.BACKEND_URL // => 'https://beta.youhodler.com/api'
 *
 * `${ENVIRONMENT.BACKEND_URL}/auth/me` // => 'https://beta.youhodler.com/api/auth/me';
 * ```
 */
export const makeUrl = (path: string, _type: ApplicationType, env: AppEnv) => {
  const _path = path.startsWith('/') ? path.substring(1) : path;

  return `${getWebAppUrl(env)}/${_path}`;
};
