import EventEmitter from 'eventemitter3';
import {invariant} from '@youtoken/ui.utils';
import {AllEvents, NeverParams} from './events';

export type ParamsByRouteName<
  RouteName extends AllEvents[0],
  Params = Extract<AllEvents, [RouteName, any]>[1]
> = Params;

export type TrackStrict = <RouteName extends AllEvents[0]>(
  name: RouteName,
  ...args: ParamsByRouteName<RouteName> extends NeverParams
    ? [params?: ParamsByRouteName<RouteName>]
    : [params: ParamsByRouteName<RouteName>]
) => void;

type LegacyDataLayerServiceTrack = (spec: {
  event: string;
  properties?: {[key: string]: any};
  options?: {[key: string]: any};
}) => any;

export class LegacyDataLayerService {
  private _track?: LegacyDataLayerServiceTrack;

  public events = new EventEmitter<'set'>();

  constructor() {}

  public get track(): LegacyDataLayerServiceTrack {
    invariant(
      this._track,
      `"track" in Data Layer Service was not set, "track"="${this._track}"`
    );

    return this._track;
  }

  public set track(track: LegacyDataLayerServiceTrack) {
    this._track = track;
    this.events.emit('set', 'track', track);
  }

  public trackStrict: TrackStrict = (eventName, eventPropperties) => {
    invariant(
      this._track,
      `"track" in Data Layer Service was not set, "track"="${this._track}"`
    );

    return this._track({
      event: eventName,
      properties: eventPropperties,
    });
  };
}
