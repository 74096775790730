import {useResponsiveProp, ResponsiveValue, PropValue} from '@shopify/restyle';
import {type Theme} from '../themes';

export const useResponsive = <T extends PropValue>(
  prop: ResponsiveValue<T, Theme['breakpoints']>
) => {
  return useResponsiveProp<Theme, T>(prop);
};

export const useIsSmallMobile = () => {
  //  NOTE: starting with phone => false, default true
  return (
    useResponsive({
      default: 1,
      phone: 0,
    }) > 0
  );
};

export const useIsMobile = () => {
  return (
    useResponsive({
      default: 1,
      smallPhone: 1,
      phone: 1,
      tablet: 0,
      desktop: 0,
    }) > 0
  );
};

export const useIsTablet = () => {
  return (
    useResponsive({
      default: 0,
      smallPhone: 0,
      phone: 0,
      tablet: 1,
      desktop: 0,
    }) > 0
  );
};

export const useIsDesktop = () => {
  return (
    useResponsive({
      default: 0,
      smallPhone: 0,
      phone: 0,
      tablet: 0,
      desktop: 1,
    }) > 0
  );
};
