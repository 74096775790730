import * as React from 'react';
import {Text as NativeText} from 'react-native';
import {createText} from './createText';
import {type TextProps} from './createRestyledText';
import {TextStyleContext, useMergedTextStyle} from './TextStyleContext';

export {createText};
export {TextStyleContext};
export {useMergedTextStyle};
export type {TextProps};

export const Text = createText(NativeText);

// export const Text: React.FC<React.ComponentProps<typeof StyledText>> =
//   React.forwardRef((props, ref) => {
//     const {children, weight, color, variant, ...restProps} = props;

//     const mergedProps = useMergedTextStyle(props);

//     return (
//       <TextStyleContext.Provider value={mergedProps}>
//         <StyledText
//           ref={ref}
//           accessible
//           accessibilityRole="text"
//           {...mergedProps}
//           {...restProps}
//         >
//           {children}
//         </StyledText>
//       </TextStyleContext.Provider>
//     );
//   });
