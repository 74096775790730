import {invariant} from '@youtoken/ui.utils';

const hexRegex = /^[#]([0-9A-F]{1,2})([0-9A-F]{1,2})([0-9A-F]{1,2})$/i;

const parse = (v: string) => parseInt(v, 16);

export const hexToRGBA = (hex: string, alpha: number = 1): string => {
  const result = hexRegex.exec(hex);

  invariant(
    result,
    `color passed to "hexToRGBA" is not valid hex color: "${hex}"`
  );

  const [_string, R, G, B] = result;

  const doubledR = R.length === 1 ? R + R : R;
  const doubledG = G.length === 1 ? G + G : G;
  const doubledB = B.length === 1 ? B + B : B;

  return `rgba(${parse(doubledR)}, ${parse(doubledG)}, ${parse(
    doubledB
  )}, ${alpha})`;
};
