import {
  BaseTheme,
  createRestyleFunction,
  ResponsiveValue,
} from '@shopify/restyle';
import {warning} from '@youtoken/ui.utils';

export const backgroundColor = createRestyleFunction({
  property: 'backgroundColor',
  styleProperty: 'backgroundColor',
  themeKey: 'colors',
  // important part - accept value as is if not found in themeKey
  transform: ({value, theme, themeKey}) => {
    if (theme[themeKey]?.[value]) {
      return theme[themeKey][value];
    }

    warning(false, `backgroundColor value is not in theme`, {
      themeKey,
      value,
    });

    return value;
  },
});

export const backgroundColorShorthand = createRestyleFunction({
  property: 'bg',
  styleProperty: 'backgroundColor',
  themeKey: 'colors',
  // important part - accept value as is if not found in themeKey
  transform: ({value, theme, themeKey}) => {
    if (theme[themeKey]?.[value]) {
      return theme[themeKey][value];
    }

    warning(false, `'backgroundColorShorthand value is not in theme`, {
      themeKey,
      value,
    });

    return value;
  },
});

export interface BackgroundColorProps<Theme extends BaseTheme> {
  backgroundColor?: ResponsiveValue<keyof Theme['colors'], Theme>;
}

export interface BackgroundColorShorthandProps<Theme extends BaseTheme> {
  bg?: ResponsiveValue<keyof Theme['colors'], Theme>;
}
