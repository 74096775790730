import {WarningError} from '@youtoken/ui.errors';
import {reportWarningOnce} from './reportWarningOnce';

/** prints warning in console if condition is false */
export function warning(
  condition: any,
  message: string,
  tags?: Record<string, any>,
  extra?: Record<string, any>
) {
  if (condition) {
    return;
  }
  // this will show stack trace for error;
  // stacktrace will also be shown in sentry;
  try {
    const error = new WarningError(message, extra, tags);

    throw error;
  } catch (_syntheticWarningError) {
    reportWarningOnce(_syntheticWarningError);

    if (typeof console !== 'undefined') {
      console.warn(
        `Warning: ${message}, ${JSON.stringify(
          {
            extra: extra,
            tags: tags,
          },
          null,
          2
        )}`
      );
    }
  }
}
