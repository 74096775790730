import {observable, action, computed} from 'mobx';
import {debounce} from 'lodash';
import {Platform, Appearance} from 'react-native';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {themes} from '@youtoken/ui.primitives';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {WebAppEnv} from '@youtoken/ui.env-utils';
import {SIMPLE_STORAGE} from '@youtoken/ui.service-storage';

export const STORAGE_THEME_KEY = '__APP_THEME__';

export const LEDGER_LIVE_THEME_KEY = '__LEDGER_LIVE_THEME__';

export class AppearanceServiceModule {
  constructor() {
    Appearance.addChangeListener(
      // okay, this is stupid, but it's the only way to get the correct value
      // https://github.com/facebook/react-native/issues/28525
      // hhttps://github.com/expo/expo/issues/10815#issuecomment-719113200
      debounce(() => {
        this.setOsAppearance(Appearance.getColorScheme() ?? 'light');
      }, 500)
    );
  }

  @observable
  osAppearance: 'light' | 'dark' = getOsAppearance();

  @observable
  userPreferredTheme: 'light' | 'dark' | 'system' =
    (SIMPLE_STORAGE.get(STORAGE_THEME_KEY) as 'light' | 'dark' | 'system') ??
    'system';

  @computed
  get activeTheme(): 'light' | 'dark' {
    if (this.userPreferredTheme === 'system') {
      return this.osAppearance;
    }

    return this.userPreferredTheme;
  }

  @computed
  get activeThemeType(): 'light' | 'dark' {
    return this.activeTheme;
  }

  @action
  setOsAppearance(newAppearance: 'light' | 'dark') {
    this.osAppearance = newAppearance;
  }

  @action
  setUserPreferredTheme = (newAppearance: 'light' | 'dark' | 'system') => {
    DATA_LAYER.trackStrict('appearance-switch', {
      previous: this.userPreferredTheme,
      current: newAppearance,
    });

    this.userPreferredTheme = newAppearance;
    SIMPLE_STORAGE.set(STORAGE_THEME_KEY, newAppearance);
  };
}

const getOsAppearance = Platform.select({
  native: () => {
    return Appearance.getColorScheme() ?? 'light';
  },
  web: () => {
    let themeFromQueryString;

    if (typeof window !== 'undefined') {
      themeFromQueryString = new URLSearchParams(window.location.search).get(
        'theme'
      );
    } else {
      themeFromQueryString = 'light';
    }
    if (themeFromQueryString && themes[themeFromQueryString]) {
      if (ENVIRONMENT.WEB_APP_ENV === WebAppEnv['ledger-app']) {
        SIMPLE_STORAGE.set(LEDGER_LIVE_THEME_KEY, themeFromQueryString);
      } else {
        SIMPLE_STORAGE.set(STORAGE_THEME_KEY, themeFromQueryString);
      }

      return themeFromQueryString as keyof typeof themes;
    }

    // if this is inside the ledger app, check for override in local storage
    if (ENVIRONMENT.WEB_APP_ENV === WebAppEnv['ledger-app']) {
      const themeFromLocalStorage = SIMPLE_STORAGE.get(LEDGER_LIVE_THEME_KEY);

      if (themeFromLocalStorage && themes[themeFromLocalStorage]) {
        return themeFromLocalStorage as keyof typeof themes;
      }
    }

    // if there is no override in the query string or override for ledger-live, use the OS theme
    return Appearance.getColorScheme() ?? 'light';
  },
})!;
